<template>
  <div class="home">
    Cunk.
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
